import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllStrapiArticle, QueryResult, StrapiService } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import ServicesDesign from '~/views/Services/Design/Design.view'

export type ServicesDesignPageProps = QueryResult<
  AllStrapiArticle & StrapiService
>

const ServicesDesignPage = ({ data }: ServicesDesignPageProps) => (
  <ServicesDesign data={data} />
)

export default ServicesDesignPage

export const query = graphql`
  query ($published: [Boolean]) {
    strapiService(page_name: { eq: "UI/UX Design" }) {
      ...MetaTagInformationServices
    }

    allStrapiArticle(
      limit: 3
      sort: { createdAt: DESC }
      filter: {
        active: { eq: true }
        published: { in: $published }
        tags: { elemMatch: { name: { eq: "UI/UX" } } }
      }
    ) {
      edges {
        node {
          id
          url
          preview_image_alt
          category {
            name
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          title
          meta_description
          modified_date
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
        }
      }
    }
  }
`

export const Head = ({ data }: ServicesDesignPageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiService,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/services/ui-ux-design/"
      isBreadcrumbs
      secondLevel="Services"
      secondLevelPath="/services/"
      current="UI/UX Design Services"
      currentPath="/services/ui-ux-design/"
    />
  )
}
